<template>
	<div
		data-component="wysiwyg-field"
	>
		<validation-provider
			tag="div"
			v-slot="{ errors, required }"
			:rules="rules"
			:vid="getVid"
			:name="getName"
		>
			<label
				data-element="label"
				v-if="getLabel"
				:for="getId"
				:data-required="required"
				v-html="getLabel"
				:data-error="errors && errors[0]"
			/>
			<input
				type="hidden"
				:value="value"
				tabindex="-1"
			/>
			<quill-editor
				data-element="quill"
				ref="editor"
				@change="onChange($event)"
				:options="options"
				:data-error="errors[0]"
			/>
			<p
				data-element="error"
				v-if="errors[0]"
			>
				{{ errors[0] }}
			</p>
		</validation-provider>
	</div>
</template>

<script>

	import sanitizeHtml            from 'sanitize-html';
	import { paramCase }           from 'change-case';
	import { camelCase }           from 'change-case';
	import { Quill, quillEditor }  from 'vue-quill-editor';
	import 'quill-paste-smart';

	export default {
		components: {
			quillEditor
		},
		props: {
			name: {
				type: String,
				default: ''
			},
			id: {
				type: String,
				default: ''
			},
			hasEditOptions: {
				type: Boolean,
				default: true
			},
			hasLinkControl: {
				type: Boolean,
				default: true
			},
			hasListControl: {
				type: Boolean,
				default: true
			},
			rules: {
				type: [
					String,
					Object
				],
				default: ''
			},
			value: {
				type:	String,
				default: undefined
			}
		},
		data: () => ({
			editor: undefined,
			options: {
				debug:         false,
				placeholder:   'Enter text...',
				theme:         'snow'
			},
			symbols: {
				heart:    '♥',
				diamond:  '♦',
				spade:    '♠',
				club:     '♣'
			}
		}),
		computed: {
			getName () {
				return this.name;
			},
			getLabel () {
				return this.name;
			},
			getId () {
				if (!this.id) {
					return paramCase(this.getName);
				}
				return this.id;
			},
			getVid () {
				if (!this.id) {
					return camelCase(this.getName);
				}
				return this.id;
			},
			getError () {
				if (!this.errors ||
						!this.errors.length) {
					return false;
				}
				return this.errors[0];
			},
			toolBarOptions () {
				return [
					(this.hasEditOptions ? ['bold', 'italic'] : false),
					(this.hasListControl ? [{ list: 'bullet' }] : false),
					['heart', 'diamond', 'spade', 'club'],
					(this.hasLinkControl ? ['link'] : false)
				].filter(Boolean);
			},
			createHandlers () {
				const symbols = this.symbols;
				return {
					heart: () => {
						this.addSymbol(symbols.heart, true);
					},
					diamond: () => {
						this.addSymbol(symbols.diamond, true);
					},
					spade: () => {
						this.addSymbol(symbols.spade, false);
					},
					club: () => {
						this.addSymbol(symbols.club, false);
					}
				};
			}
		},
		created () {
			this.extendBlot();
			this.options = {
				...this.options,
				modules: {
					toolbar: {
						container: this.toolBarOptions,
						handlers: this.createHandlers
					}
				}
			};
		},
		mounted () {
			const quill = this.$refs.editor.quill;
			quill.container.firstChild.innerHTML = this.value;
			quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
				const plaintext = node.innerText;
				const Delta = Quill.import('delta');
				return new Delta().insert(plaintext);
			});
			this.removeButtonTabIndexes();
		},
		methods: {
			extendBlot () {
				const Embed = Quill.import('blots/embed');
				class SpanEmbed extends Embed {
					static create ({ value, isRed }) {
						const node = super.create();
						node.setAttribute('data-colour', isRed ? 'red' : 'black');
						node.setAttribute('data-element', 'suit-symbol');
						node.innerText = value;
						return node;
					}
				}
				SpanEmbed.blotName = 'span';
				SpanEmbed.tagName = 'span';
				Quill.register(SpanEmbed);
			},
			onChange ({ quill, html, text }) {
				const sanitize = sanitizeHtml(html, {
					allowedTags: ['strong', 'em', 'ul', 'li', 'p', 'br', 'span', 'a'],
					allowedAttributes: {
						span: ['class', 'data-colour', 'data-element'],
						a: ['href', 'target']
					}
				});
				this.$emit('input', sanitize);
			},
			addSymbol (html, isRed) {
				const editor = this.$refs.editor.quill;
				const range = editor.getSelection();
				editor.insertEmbed(range.index, 'span', { value: html, isRed: isRed });
				editor.setSelection(range.index + 1);
			},
			removeButtonTabIndexes () {
				this.$el.querySelectorAll('.ql-toolbar button').forEach((button) => {
					button.tabIndex = -1;
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='wysiwyg-field'] {
		::v-deep [data-element='quill'] {
			.ql-toolbar {
				display: flex !important;
				align-items: center;
				border-top-right-radius:5px;
				border-top-left-radius:5px;
				.ql-heart,
				.ql-diamond,
				.ql-club,
				.ql-spade {
					&,
					&:before {
						justify-content: center;
						display: flex;
						align-items: center;
						padding: 0 !important;
					}
					&:before {
						font-family: 'Noto Sans TC', sans-serif;
						font-size: 15px;
					}
				}
				.ql-heart:before {
					content: '♥';
					color: $c-red;
				}
				.ql-diamond:before {
					content: '♦';
					color: $c-red;
				}
				.ql-spade:before {
					color:$c-black;
					content: '♠';
				}
				.ql-club:before {
					color:$c-black;
					content: '♣';
				}
			}
			.ql-container {
				border-bottom-right-radius:5px;
				border-bottom-left-radius:5px;
				.ql-editor {
					min-height: 150px;
					font-family: 'Source Sans Pro', sans-serif;
					font-size:rem(18);
					@include rich-text-suits;
					p {
						margin:0 0 0.8em;
					}
					ul,
					ol {
						margin:0 0 0.8em;
					}
				}
			}
			&[data-error] {
				.ql-toolbar,
				.ql-container {
					border-color:$c-red;
				}
			}
		}
	}

</style>
